import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFirestore} from "@angular/fire/firestore";
import { AngularFireModule } from '@angular/fire';

import { environment } from '../environments/environment';
import { MatCardModule } from '@angular/material/card';
import { ImgDocComponent } from './admin/modals/imgDoc/img-doc/img-doc.component';
//import { SafePipeModule } from 'safe-pipe';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireStorageModule,
        MatCardModule,
        //SafePipeModule
    ],
    exports: [MatCardModule],
    declarations: [AppComponent, ImgDocComponent],
    providers: [AuthGuard, AngularFirestore,{ provide: LocationStrategy, useClass: HashLocationStrategy}],
    bootstrap: [AppComponent]
})
export class AppModule {}
