import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-img-doc',
  templateUrl: './img-doc.component.html',
  styleUrls: ['./img-doc.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ImgDocComponent implements OnInit {
  dataSource: User;
  constructor(public dialogRef: MatDialogRef<ImgDocComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    }

  ngOnInit(): void {
    if (this.data?.userinfo) {
      this.dataSource = this.data.userinfo;
    }
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onGuardar(){
    this.dialogRef.close(true);
  }

}
